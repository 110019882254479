import * as React from "react"
import './PtasieMleczko.css';
import Creative from "../../components/Creative"
import backgroundPtasieMleczko from "./images/ptasieMleczko_background.jpg"
import packshotBlue from "./images/ptasieMleczko_packshotBlue.png"
import packshotRed from "./images/ptasieMleczko_packshotRed.png"
import chocolateBlue from "./images/ptasieMleczko_chocolateBlue.png"
import chocolateRed from "./images/ptasieMleczko_chocolateRed.png"
import video from "./images/ptasieMleczko_video.mp4"
import backImage from "./images/ptasieMleczko_backImage.png"
import SimpleSidebar from "../../components/layout"

const PtasieMleczko = () => (
  <SimpleSidebar>
    <Creative
      className="ptasieMleczko"
      video={video}
      background={backgroundPtasieMleczko}
      backImage={backImage}
      heading="Ptasie Mleczko"
      tags={["compare", "animation"]}
      tagBackground="#ffffff"
      tagColor="#347cb3"
      headingColor="#ffffff"
      variant="rightTop"
    >
      <img src={packshotBlue} className="ptasieMleczko_image packshotBlue"/>
      <img src={packshotRed} className="ptasieMleczko_image packshotRed"/>
      <img src={chocolateBlue} className="ptasieMleczko_image chocolateBlue"/>
      <img src={chocolateRed} className="ptasieMleczko_image chocolateRed"/>
    </Creative>
  </SimpleSidebar>
)

export default PtasieMleczko
